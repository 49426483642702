import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { media, Row, Col } from 'styled-bootstrap-grid';

import { useAuthState } from '../../../../../hooks/auth-context';
import { AccountFieldSection } from '../../../common';
import { rem, lh } from '../../../../../styles/utils';
import SavedAddressForm from './saved-address-form';
import { deleteUserAddress, getUserAddresses } from '../../../../../utils/api';
import { Spinner, Badge } from '../../../../common';
import { toast } from 'react-toastify';
import { UserAddress } from '../../../../../types/user';

const Container = styled.div`
  font-family: var(--font-body);
  color: var(--font-primary-color);
  margin-top: 30px;

  ${media.lg`
      margin-top: 40px;
  `}
`;

const LabelContainer = styled.div``;

const LabelGroup = styled.div`
  font-size: ${rem(14)};
  line-height: ${lh(15.4, 14)};

  ${media.lg`
    font-size: ${rem(16)};
    line-height: ${lh(21, 16)};
  `}

  p {
    margin: 5px 0;
  }
`;

const BadgeGroup = styled.div`
  margin-top: 12px;
`;

const AddressBadge = styled(Badge)`
  background-color: var(--catskill-white);
  color: var(--font-primary-color);
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

type Props = {
  className?: string;
};

const SavedAddresses: React.FC<Props> = () => {
  const { accessToken, email } = useAuthState();
  const [isLoading, setIsLoading] = useState(false);
  const [addresses, setAddresses] = useState<UserAddress[]>([]);

  useEffect(() => {
    if (!accessToken) return;
    (async function () {
      await loadUserAddress();
    })();
  }, [accessToken]);

  const loadUserAddress = async () => {
    setIsLoading(true);
    const response = await getUserAddresses(accessToken);
    setAddresses(response);
    setIsLoading(false);
  };

  const handleDelete = async (address: UserAddress) => {
    setIsLoading(true);
    const result = await deleteUserAddress(accessToken, address);
    if (result) {
      toast.success('Successfully delete address');
    } else {
      toast.error('An error has occured. Please contact support via Whatsapp.');
    }
    setIsLoading(false);
    loadUserAddress();
  };

  return (
    <Container>
      <AccountFieldSection
        headline="Saved Addresses"
        onRenderForm={address => (
          <SavedAddressForm
            onAfterSubmit={async () => await loadUserAddress()}
            address={address}
          />
        )}
        onRenderLabel={({
          firstName,
          lastName,
          address,
          country,
          postalCode,
          contactNo,
          isDefaultBillingAddress,
          isDefaultShippingAddress,
        }) => (
          <LabelContainer>
            <LabelGroup>
              <strong>
                {firstName} {lastName}
              </strong>
              <p>
                {address}
                <br />
                {country} {postalCode}
                <br />
                {contactNo}
              </p>
            </LabelGroup>
            {(isDefaultBillingAddress || isDefaultShippingAddress) && (
              <BadgeGroup>
                {isDefaultBillingAddress && (
                  <AddressBadge>Default Billing</AddressBadge>
                )}
                {isDefaultShippingAddress && (
                  <AddressBadge>Default Shipping</AddressBadge>
                )}
              </BadgeGroup>
            )}
          </LabelContainer>
        )}
        multiple={{
          enabled: true,
          buttonText: 'Add new address',
          initialData: {
            firstName: '',
            lastName: '',
            email,
            country: '',
            company: '',
            address: '',
            postalCode: '',
            contactNo: '',
            notes: '',
            isDefaultBillingAddress: false,
            isDefaultShippingAddress: false,
          },
        }}
        data={addresses}
        onDelete={handleDelete}
      />
      <Spinner isLoading={isLoading} />
    </Container>
  );
};

export default SavedAddresses;
