import React from 'react';

import {
  AccountHero,
  AccountLayout,
  AccountProfileLayout,
} from '../../../components/account/common';
import SvgBack from '../../../static/images/account-back-icon.svg';
import { SiteUrls } from '../../../utils/constants';
import {
  PersonalInfo,
  SavedAddresses,
} from '../../../components/account/profile/general';

const AccountProfileGeneralPage = (): JSX.Element => (
  <AccountLayout
    hero={
      <AccountHero
        disableBackground
        toolbarLink={{
          icon: <SvgBack />,
          to: SiteUrls.AccountDashboard,
          text: 'Back to dashboard',
        }}
      />
    }
  >
    <AccountProfileLayout>
      <PersonalInfo />
      <SavedAddresses />
    </AccountProfileLayout>
  </AccountLayout>
);

export default AccountProfileGeneralPage;
