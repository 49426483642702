import React from 'react';
import styled from 'styled-components';
import { media, Row, Col } from 'styled-bootstrap-grid';
import { useForm } from 'react-hook-form';

import { useAuthState } from '../../../../hooks/auth-context';
import { AccountFieldSection } from '../../common';
import { FormInput } from '../../../common';
import { updateCurrentUser } from '../../../../utils/api';
import { rem, lh, flatButtonStyle } from '../../../../styles/utils';
import { toast } from 'react-toastify';

const Container = styled.div`
  font-family: var(--font-body);
  color: var(--font-primary-color);
`;

const Form = styled.form``;

const LabelContainer = styled.div``;

const LabelGroup = styled.div`
  display: flex;
  font-size: ${rem(14)};
  line-height: ${lh(15.4, 14)};

  ${media.lg`
  font-size: ${rem(16)};
  line-height: ${lh(21, 16)};
`}
`;

const LabelText = styled.label`
  min-width: 86px;
  margin-right: 15px;

  ${media.lg`
    min-width: 140px;
    margin-right: 30px; 
  `}
`;

const LabelValue = styled.span`
  font-weight: 600;
`;

const UpdateButton = styled.button`
  ${flatButtonStyle()}
`;

type FormData = {
  firstName: string;
  lastName: string;
};

type Props = {
  className?: string;
};

const PersonalInfo: React.FC<Props> = () => {
  const { firstName, lastName, email, accessToken } = useAuthState();
  const { register, handleSubmit, errors } = useForm<FormData>({
    defaultValues: { firstName, lastName },
  });

  const hasErrors = () => Object.keys(errors).length > 0;

  const onSubmit = async (data: FormData) => {
    if (hasErrors()) return;

    const { firstName, lastName } = data;
    const result = await updateCurrentUser(accessToken, {
      firstName,
      lastName,
    });

    if (!result) {
      toast.error('An error has occured. Please contact support via Whatsapp.');
      return;
    }

    toast.success('Your profile has been successfully updated');
  };

  return (
    <Container>
      <AccountFieldSection
        headline="Personal Information"
        onRenderForm={() => (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={6}>
                <FormInput
                  label="First name"
                  error={errors.firstName}
                  inputRef={register({
                    required: 'First name is required',
                  })}
                  inputProps={{
                    name: 'firstName',
                  }}
                />
              </Col>
              <Col md={6}>
                <FormInput
                  label="Last name"
                  error={errors.lastName}
                  inputRef={register({
                    required: 'Last name is required',
                  })}
                  inputProps={{
                    name: 'lastName',
                  }}
                />
              </Col>
            </Row>
            <UpdateButton type="submit">Update</UpdateButton>
          </Form>
        )}
        onRenderLabel={() => (
          <LabelContainer>
            <LabelGroup>
              <LabelText>First name</LabelText>
              <LabelValue>{firstName}</LabelValue>
            </LabelGroup>
            <LabelGroup>
              <LabelText>Last name</LabelText>
              <LabelValue>{lastName}</LabelValue>
            </LabelGroup>
            <LabelGroup>
              <LabelText>Email address</LabelText>
              <LabelValue>{email}</LabelValue>
            </LabelGroup>
          </LabelContainer>
        )}
        data={[{ firstName, lastName, email }]}
      />
    </Container>
  );
};

export default PersonalInfo;
