import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'styled-bootstrap-grid';
import { useForm } from 'react-hook-form';

import { useAuthState } from '../../../../../hooks/auth-context';
import { FormInput, FormCheckbox, Spinner } from '../../../../common';
import { flatButtonStyle } from '../../../../../styles/utils';
import { createUserAddress, updateUserAddress } from '../../../../../utils/api';
import { toast } from 'react-toastify';
import { UserAddress } from '../../../../../types/user';

const Form = styled.form``;

const Button = styled.button`
  ${flatButtonStyle()}
  margin-top: 20px;
`;

type Props = {
  className?: string;
  address?: UserAddress;
  onAfterSubmit?: () => void;
};

const SavedAddressForm: React.FC<Props> = ({
  className,
  address,
  onAfterSubmit,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { accessToken } = useAuthState();
  const { register, handleSubmit, errors } = useForm<UserAddress>({
    defaultValues: {
      ...address,
    },
  });

  const onSubmit = async (data: UserAddress) => {
    let result = false;
    let message = '';
    data.id = address?.id || '';
    data.email = address?.email || '';

    setIsLoading(true);

    if (!data.id) {
      result = await createUserAddress(accessToken, data);
      message = 'Successfully added new address';
    } else {
      result = await updateUserAddress(accessToken, data);
      message = 'Successfully updated address';
    }

    setIsLoading(false);

    if (result) {
      toast.success(message);
    } else {
      toast.error('An error has occured. Please contact support via Whatsapp.');
    }

    if (result && typeof onAfterSubmit === 'function') {
      onAfterSubmit();
    }
  };

  return (
    <Form className={className} onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col md={6}>
          <FormInput
            label="First name"
            error={errors.firstName}
            inputRef={register({
              required: 'First name is required',
            })}
            inputProps={{
              name: 'firstName',
            }}
          />
        </Col>
        <Col md={6}>
          <FormInput
            label="Last name"
            error={errors.lastName}
            inputRef={register({
              required: 'Last name is required',
            })}
            inputProps={{
              name: 'lastName',
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormInput
            label="Country"
            error={errors.country}
            inputRef={register({
              required: 'Country is required',
            })}
            inputProps={{
              name: 'country',
            }}
          />
        </Col>
        <Col md={6}>
          <FormInput
            label="Company"
            error={errors.company}
            inputRef={register()}
            inputProps={{
              name: 'company',
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <FormInput
            label="Street address"
            error={errors.address}
            inputRef={register({
              required: 'Street address is required',
            })}
            inputProps={{
              name: 'address',
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormInput
            label="Postal code"
            error={errors.postalCode}
            inputRef={register({
              required: 'Postal code is required',
            })}
            inputProps={{
              name: 'postalCode',
            }}
          />
        </Col>
        <Col md={6}>
          <FormInput
            label="Contact no."
            error={errors.contactNo}
            inputRef={register({
              required: 'Contact no. is required',
            })}
            inputProps={{
              name: 'contactNo',
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col col={12}>
          <FormInput
            error={errors.notes}
            label="Notes (optional)"
            tag="textarea"
            inputRef={register()}
            textAreaProps={{
              name: 'notes',
              placeholder: 'Special delivery instructions etc.',
              rows: 5,
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col col={12}>
          <FormCheckbox
            inputRef={register()}
            inputProps={{
              name: 'isDefaultShippingAddress',
              id: 'isDefaultShippingAddress',
            }}
            label="Default Shipping Address"
          />
          <br />
          <FormCheckbox
            inputRef={register()}
            inputProps={{
              name: 'isDefaultBillingAddress',
              id: 'isDefaultBillingAddress',
            }}
            label="Default Billing Address"
          />
        </Col>
      </Row>
      <Button type="submit">Save</Button>
      <Spinner isLoading={isLoading} />
    </Form>
  );
};

export default SavedAddressForm;
